// 'use client'

// import { motion } from 'framer-motion'
// import { useInView } from '@/hooks/useInView'
// import Link from 'next/link'
// import Image from 'next/image'

// import imageSrc from '@/public/images/bannermoh.jpg'
// import { useRTLAwareStyle } from '@/util/rtl'

// type Props = {
//   title1: string;
//   title2: string;
//   lang: string;
//   buttonText: string;
//   buttonLink: string;
// }

// export default function Hero({
//   title1, 
//   title2, 
//   lang, 
//   buttonText, 
//   buttonLink
// }: Props) {
//   const { ref, isInView } = useInView()
//   const rtlAwareUnderlineStyle = useRTLAwareStyle('left-0', 'right-0');

//   return (
//     <motion.section
//       ref={ref}
//       initial={{ opacity: 0 }}
//       animate={isInView ? { opacity: 1 } : {}}
//       transition={{ duration: 0.8, ease: 'easeOut' }}
//       className="w-full xl:container mx-auto relative flex flex-col items-center"
//     >
//       <div className="relative w-full h-[200px] md:h-[400px] lg:h-[550px] overflow-hidden">
//         <Image
//        priority
//        src={imageSrc}
//        alt= 'test'
//        quality={85}
//        fill
//        sizes="(max-width: 768px) 100vw, 100vw"
//        className="object-cover"
//         />
//       </div>
  
//         <Link
//           href={buttonLink}
//           className="inline-block relative group mt-4"
//           prefetch={false}
//         >
//           <span className="text-gray-600 relative z-10 transition-opacity duration-300 ease-in-out group-hover:opacity-70 font-roboto">
//           {buttonText}
//           </span>
//           <span className={`absolute bottom-0 ${rtlAwareUnderlineStyle} w-full h-[1px] bg-black transition-all duration-700 ease-in-out group-hover:w-0`}></span>
//         </Link>

      
//       <motion.h1
//         initial={{ opacity: 0, y: 20 }}
//         animate={isInView ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 0.6, delay: 0.4, ease: 'easeOut' }}
//         className={`my-4 md:my-10 text-xl md:text-2xl text-center flex font-roboto ${lang === 'en' && "test"}`}
//       >
//         {title1} {title2}
//       </motion.h1>
      
//       <motion.div
//         initial={{ opacity: 0, y: 20 }}
//         animate={isInView ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 0.6, delay: 0.6, ease: 'easeOut' }}
//         className="mb-6"
//       >
//       </motion.div>
//     </motion.section>
//   )
// }



"use client"

import { motion } from "framer-motion"
import { useInView } from "@/hooks/useInView"
import Link from "next/link"
import Image from "next/image"
import Slider from "react-slick"
import { useRTLAwareStyle } from "@/util/rtl"

// Import the slick-carousel styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Import your images
import image1 from "@/public/images/valentine-b1.jpg"
import image2 from "@/public/images/valentine-b2.jpg"
import image3 from "@/public/images/valentine-b3.jpg"
import image4 from "@/public/images/valentine-b4.jpg"

type Props = {
  title1: string
  title2: string
  lang: string
  buttonText: string
  buttonLink: string
}

export default function Hero({ title1, title2, lang, buttonText, buttonLink }: Props) {
  const { ref, isInView } = useInView()
  const rtlAwareUnderlineStyle = useRTLAwareStyle("left-0", "right-0")

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  }

  const images = [image1, image2, image3, image4]

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="w-full xl:container mx-auto relative flex flex-col items-center"
    >
      <div className="relative w-full h-[200px] md:h-[400px] lg:h-[550px] overflow-hidden">
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className="relative w-full h-[200px] md:h-[400px] lg:h-[550px]">
              <Image
                priority={index === 0}
                src={img || "/placeholder.svg"}
                alt={`Slide ${index + 1}`}
                quality={85}
                fill
                sizes="(max-width: 768px) 100vw, 100vw"
                className="object-cover"
              />
            </div>
          ))}
        </Slider>
      </div>

      <Link href={buttonLink} className="inline-block relative group mt-4" prefetch={false}>
        <span className="text-gray-600 relative z-10 transition-opacity duration-300 ease-in-out group-hover:opacity-70 font-roboto">
          {buttonText}
        </span>
        <span
          className={`absolute bottom-0 ${rtlAwareUnderlineStyle} w-full h-[1px] bg-black transition-all duration-700 ease-in-out group-hover:w-0`}
        ></span>
      </Link>

      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.4, ease: "easeOut" }}
        className={`my-4 md:my-10 text-xl md:text-2xl text-center flex font-roboto ${lang === "en" && "test"}`}
      >
        {title1} {title2}
      </motion.h1>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.6, ease: "easeOut" }}
        className="mb-6"
      ></motion.div>
    </motion.section>
  )
}